<template>
    <div class="autocompleteinput">
        <div class="account-code form-group" v-bind:class="{ required: required }">
            <input type="text" v-bind:id="field" v-bind:name="field" :required="required" v-bind:maxlength="max_length" v-model="input" :disabled="disabled">
            <label v-bind:for="field">{{label}}</label>
            <div v-if="results.length > 0" class="dropdown">
                <div class="results" v-for="result in results" v-bind:key="result.id">
                    <p @click="selectResult(result.accountCode)">{{result.accountCode}} - {{result.accountName}}</p>
                </div>
            </div>
        </div>
        <div class="form-group" v-bind:class="{ required: required , spinner: loading}"><div></div>
            <input type="text" id="email" name="email" required v-model="email_value" :disabled="loading || disabled">
            <label for="email">Email</label>
        </div>
    </div>
</template>

<script>
import autoCompleteMixin from "@/mixins/autoCompleteMixin.js";
import api from '@/API/APIManager.js';
const API = new api();

export default {
    name:'accountCodeCompleteInput',
    mixins: [autoCompleteMixin], 
    data: function() {
        return {
            email_value: this.email_prop,
        }
    },
    props: {
        email_prop: {
            type: String,
            required: true,
            default: "",
        },
    },
    watch: {
        email_value:function(newVal) {
            this.$emit('email', newVal );
        },
        email_prop: function(newVal) {                      
            this.email_value = newVal;
        }
    },
    methods:{
        //Override the autocomplete to for different api calls.
        getAutoCompleteResults: async function() {
            this.loading = true;
            var response = await API.parts_manager.getAccountCodes(this.input);

            //this.loading= false;
            if (response) {            
                this.results = response.data;
                this.email_value = "";
            }            
        },
        selectResult: async function(result) {
            this.$emit('update',result);
            this.results = [];
            this.loading = true;            
            var response = await API.parts_manager.getAccountEmail(result);


            this.loading = false;
            if (response && response.data.email) {
                this.email_value = response.data.email;
            }            
        },
    }  
      
}
</script>

<style lang="scss" scoped>

.dropdown {
    z-index: 10;
    background: white;
    width: 100%;
    border: 0.1px solid gray;
    margin-bottom: 1.2em;
    max-height: 400px;
    overflow-y: auto;
}

.results p, .results-override {
        cursor:pointer;
        padding:0.25rem 0.5rem;
        display: inline-block;
        width: 100%;
        text-align: left;        

        &:hover {
            background:var(--primary-100);
        }
    }

   

</style>