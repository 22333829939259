<template>
    <div class="autocompleteinput form-group" v-bind:class="{ required: required }">
        <input type="text" v-bind:id="field" v-bind:name="field" :required="required" v-bind:maxlength="max_length" v-model="input">
        <label v-bind:for="field">{{label}}</label>
        <div v-if="results.length > 0" class="dropdown">
            <div class="results" v-for="result in results" v-bind:key="result.part">
                <p @click="selectResult(result.part)">{{result.part}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import autoCompleteMixin from "@/mixins/autoCompleteMixin.js";
import api from '@/API/APIManager.js';
const API = new api();

export default {
    name:'PartCompleteInput',
    mixins: [autoCompleteMixin], 
    props:{
        accountCode: {
            type: [String, Number],
            required: true,
            default: -1,
        }
    },
    methods:{
        //Override the autocomplete to for different api calls.
        getAutoCompleteResults: async function() {
            
            var response = await API.parts_manager.getReturnParts(this.input, this.accountCode);
            if (response) {            
                this.results = response.data;
            }
        },
    }
    
}
</script>

<style lang="scss" scoped>

.dropdown {
    z-index: 10;
    background: white;
    width: 100%;
    border: 0.1px solid gray;
    margin-bottom: 1.2em;
}

.results p {
        cursor:pointer;
        padding:0.25rem 0.5rem;
        display: inline-block;
        width: 100%;
        text-align: left;        

        &:hover {
            background:var(--primary-100);
        }
    }
    

</style>