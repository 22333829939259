<template>
    <section>
        <form class="main-entity" id="credit-return-form" v-on:submit.prevent="handleCreateReturn" autocomplete="off" ref="returnForm">
            
                <h2>OCR</h2>

                <div>                    
                    <!-- Change Status -->
                    <button v-if="this.account_details.id && this.account_details.status == 'Open'" type="button" class="btn" @click="changeStatusToPending()">Change to Pending</button>
                    
                    <AccountCodeCompleteInput field="accountCode" :required="true" label="Account Code" v-bind:value="account_details.accountCode" v-on:update="account_details.accountCode = $event" :email_prop="account_details.email" v-on:email="account_details.email = $event" :disabled="account_details.status != 'Draft'"></AccountCodeCompleteInput>
                    
                    <AutoCompleteInput field="customerRef" :required="false" label="Customer ref" max-length="9" v-bind:value="account_details.customerRef" v-on:update="account_details.customerRef = $event" :disabled="account_details.status != 'Draft'"></AutoCompleteInput>
                    
                    <h2 v-if="account_details.status==='Draft'" class="mt-1">Parts</h2>
                    
                    <div v-for="(part, index) in part_array" v-bind:key="index">
                    
                        <div class="part-card">
                        
                            <button type="button" class="remove-card" @click="removeOrderLine(index)" v-if="has_parts"><img :src="require('@/assets/svg/close.svg')" title="Remove Part"></button>
                            
                            <h4>Part {{index + 1}} </h4>
                            
                            <div class="part-fields">          
                            
                                <!-- Part -->
                                <PartCompleteInput field="part" :required="true" label="Part" max-length="20" v-bind:value="part.part" v-on:update="part.part=$event" :accountCode="account_details.accountCode"></PartCompleteInput>  
                                
                                <!-- Invoice Number -->
                                <InvoiceCompleteInput field="invoiceNumber" :required="true" label="Invoice Number" max-length="10" v-bind:value="part.invoiceNumber" v-on:update="part.invoiceNumber=$event" :accountCode="account_details.accountCode" :part="part.part"></InvoiceCompleteInput>            
                                
                                <!-- Qty -->
                                <div class="form-group required card-field">
                                    <input type="number" id="qty" name="qty" required max="9999" v-model="part.qty">
                                    <label for="qty">Qty</label>
                                </div>
                                
                                <!-- Reason -->
                                <div class="form-group required">
                                    <select id="reason" name="reason" required v-model="part.reason">
                                        <option value="" selected disabled>Please select an option</option>
                                        <option v-for="reason in reasons" v-bind:key="reason.id" :value="reason.id">{{reason.name}}</option>
                                    </select>
                                    <label for="reason">Reason</label>
                                </div>                                
                            </div>
                            
                            <!-- Note -->
                            <div class="form-group">
                                <textarea id="note" name="note" maxlength="255" v-model="part.note"></textarea>
                                <label for="note">Note</label>
                            </div>
                            
                            <button type="button " class="add-card" @click="addOrderLine(index)">
                                <svg data-v-2e1e1c61="" viewBox="0 0 24 24" style="width: 24px; height: 24px;"><path data-v-2e1e1c61="" fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"></path></svg>
                            </button>
                            
                        </div>
                    </div> 
                    <!-- <button type="button" @click="view='customer'" class="btn">Go to customer</button>   -->
                </div>
                <input type="submit" class="btn btn-primary"  v-if="account_details.status==='Draft'" v-bind:value="account_details.status==='Draft' ? 'Submit Return' : 'Update Return'" :disabled="submitting">
                <input type="button" class="btn btn-primary" value="Save Draft" v-if="account_details.status==='Draft'" v-on:click="saveReturn()"> <!--Should be able to edit an existing return but still save need to flag as edited-->
                <input type="button" class="btn btn-primary"  value="Delete Draft" v-if="account_details.status==='Draft'&&account_details.key" v-on:click="deleteDraft()">
                <!-- <input type="button" class="btn"  value="Discard Edits" v-if="account_details.status==='Open'&&account_details.edited" v-on:click="discardEdits()"> -->
                <input type="button" class="btn btn-primary"  v-bind:value="account_details.status==='Draft' ? 'Cancel': 'Close'" v-on:click="returnToList()">
            </form>
    </section>
</template>

<script>
import api from '@/API/APIManager.js';
const API = new api();


import AutoCompleteInput from '@/components/AutoCompleteInput.vue';
import AccountCodeCompleteInput from '@/components/AccountCodeCompleteInput.vue';
import InvoiceCompleteInput from '@/components/InvoiceCompleteInput.vue';
import PartCompleteInput from '@/components/PartCompleteInput.vue';

export default {
    components: {
        AutoCompleteInput,
        AccountCodeCompleteInput,
        InvoiceCompleteInput,
        PartCompleteInput,
    },

    data: function() {
        return {
            account_details:{
               accountCode:"",
               email:"",
               customer_ref: "", 
               status: "Draft",
            },
            part_array: [{
                part:"",
                invoiceNumber:"",
                qty:1,
                reason:0,
                note:"",
            }],
            reasons: [],
            submitting: false,
        }
    },
    methods: {
        returnToList() {
            this.$router.push({name: 'Returns'})
        },
        async changeStatusToPending(){

                // Check we have valid data
                if ( ! this.account_details.id )
                    return false;

                // Confirmation
                if (!confirm('Confirm changing status to Pending?'))
                    return false;

                // Sending API request to update status
                var result = await API.parts_manager.updateReturnStatus(this.account_details.id, "Pending");                   
                if ( !result ){
                    alert('Failed to update status');
                } else {
                    this.account_details.status = "Pending";
                }
            },
            addOrderLine(index) {
                let part = {
                    part:"",
                    invoiceNumber:"",
                    qty:1,
                    reason:0,
                    note:"",
                };
                this.part_array.splice(index+1,0,part);                
            },
            removeOrderLine(index) {
                if (this.has_parts) {
                    this.part_array.splice(index,1);
                }                
            },
            handleCreateReturn: async function() {
                this.submitting = true;
                var response;
                // Actually sending return        
                try {
                    response = await API.parts_manager.createOCR(this.account_details.accountCode, this.account_details.email, this.account_details.customerRef, this.part_array);
                    //Might be format of how this is submitting?
                    alert("Sent OCR");
                    //Close editor and direct back to view. 
                    this.returnToList();
                } catch(error) {
                    console.log(error);
                    
                    alert("An error occured");
                }

                this.submitting = false;

                if (response) {
                    //Remove from local storage
                    //Delete from drafts.
                    this.deleteDraft();
                }
                                 
                return;
            },  
            saveReturn: function() {  
                var details = JSON.parse(JSON.stringify(this.account_details));         
                var parts = JSON.parse(JSON.stringify(this.part_array));                
                var params = {...details, parts};
                              
                var dbPromise = window.indexedDB.open('parts_manager_db', this.$store.state.local_db_version);

                if (details.key) {
                    //Update the existing store
                    dbPromise.onsuccess = (event => {
                        var db = event.target.result;    
                        var tx = db.transaction('returns', 'readwrite');
                        var returns = tx.objectStore('returns');

                        returns.put(params, details.key);                    
                        return tx.complete;
                    });                    
                } else {
                    //Adding new item
                    dbPromise.onsuccess = (event => {
                        var db = event.target.result;

                        var tx = db.transaction('returns', 'readwrite');
                        var returns = tx.objectStore('returns');
                        returns.add(params);                    
                        return tx.complete;
                    });
                }      

                this.returnToList();
            },
            deleteDraft() {                
                if (this.account_details.key && this.account_details.status === "Draft") {
                    
                    var dbPromise =  window.indexedDB.open('parts_manager_db', this.$store.state.local_db_version);                     
                    
                    dbPromise.onsuccess = (async(event) => {
                    var db = event.target.result;
                    var tx = db.transaction('returns', 'readwrite');
                    var returns = tx.objectStore('returns');

                    await returns.delete(this.account_details.key);
                    this.returnToList();

                    return tx.complete;
                });                    
                }
            }, 
    },
    computed: {
        has_parts() {
                return this.part_array.length > 1;
        },
        mainAccountCode() {
                return this.account_details.accountCode;
        },
    },
    watch: {        
        mainAccountCode: function(newVal,oldVal) {
                if (oldVal && oldVal.length > 0) {
                    this.part_array = [{
                        part:"",
                        invoiceNumber:"",
                        qty:1,
                        reason:0,
                        note:"",
                    }];
                }                
            },
    },
    created: async function() {
        
        //Get return reasons
        var response= await API.parts_manager.getReturnReasons();
        this.reasons = response.data;

        //get from id
        if (this.$route.params.invoice_id) {        
            
            this.api_returns = await API.parts_manager.getReturns({id: this.$route.params.invoice_id});
            this.account_details = this.api_returns.rows[0];

            if (this.account_details.id) {
                var parts = await API.parts_manager.getReturnItems(this.account_details.id);                   
                this.part_array = parts.data;  
            } 
        }else if(this.$route.params.key){
            //Get from indexedDb key
            
            var open = indexedDB.open("parts_manager_db", this.$store.state.local_db_version);
            var search_key = this.$route.params.key

            open.onsuccess = (event) => {
                var db = event.target.result;
                var transaction = db.transaction(["returns"]);
                var objectStore = transaction.objectStore("returns"); 
                    
                var request = objectStore.get(Number(search_key));

                request.onsuccess = () => {
                    this.account_details = request.result;
                    this.part_array = request.result.parts;
                    this.account_details.key = Number(search_key);

               }
            }
        }        
    },
    
}
</script>

<style scoped>
#credit-return-form {
        padding-top: 30px;
    }

    #credit-return-form h2:first-of-type {
        margin-bottom: 10px;
    }

    .part-card {
        padding: 0.5em 0 2.5em 0;
        margin-bottom: 1em;
        position: relative;
    }

    .part-card:first-child {
        margin-top: 1em;
    }

    .part-card button {
        padding: 0.2em;
        border-radius: 0.2em;
    }

    .part-card button.add-card {
        position: absolute;
        right: 0.1em;
        bottom: 0.1em;
    }

    .part-card button.remove-card {
        position: absolute;
        right: 0.1em;
        top: 0.1em;            
    }

    .part-card .part-fields {
        display: flex;
        flex-wrap: wrap;
        gap: 0 0.5em;
        margin-bottom: 0.5em;
    }

    .part-fields > div {
        flex-basis: 0;
        flex-grow: 1;
        min-width: 8em;
    }
</style>