<template>
    <div class="autocompleteinput form-group" v-bind:class="{ required: required }">
        <label v-bind:for="field">{{label}}</label>
        <select required v-model="input">
                <option value="" selected disabled>Please select an option</option>
                <option class="results" v-if="results.length===0 && input">{{input}}</option>
                <option class="results" v-for="result in results" v-bind:key="result.invoiceNumber" v-bind:value="result.invoiceNumber">{{parseResult(result)}}</option>
        </select> 
    </div>
</template>

<script>
import autoCompleteMixin from "@/mixins/autoCompleteMixin.js";
import api from '@/API/APIManager.js';
const API = new api();

export default {
    name:'InvoiceCompleteInput',
    mixins: [autoCompleteMixin],
    props: {
        accountCode: {
            type: [String, Number],
            required: true,
            default: -1,
            
        },
        part: {
            type: [String,Number],
            required: true,
            default: -1,
        }
    },
    watch: {
        //invalidate input if part changes
        part: async function(newVal,oldVal){
            if (oldVal === "") {
                return;
            } 

            this.input="";
            this.loading = true;
            var response = await API.parts_manager.getInvoices(this.input, this.accountCode, this.part);

            this.loading = false;

            if (response) {            
                this.results = response.data;                
            }
        }
    },
    methods:{
        //Override the autocomplete to for different api calls.
        getAutoCompleteResults: async function() {
            var response = await API.parts_manager.getInvoices(this.input, this.accountCode, this.part);

            if (response) {            
                this.results = response.data;                
            }
        },
        
        parseResult: function (v) {
            return (0 < parseInt(v.returnedQty) ? `Returned ${v.returnedQty} of ${v.partQty} already | `: '') + `Inv#${v.invoiceNumber} [${v.partQty}] at ${v.invoiceDate} with ord#${v.orderNumber} comment: ${v.partComment}`;
        } 
    }
    
}
</script>

<style lang="scss" scoped>

.dropdown {
    z-index: 10;
    background: white;
    width: 100%;
    border: 0.1px solid gray;
    margin-bottom: 1.2em;
}

.results p {
        cursor:pointer;
        padding:0.25rem 0.5rem;
        display: inline-block;
        width: 100%;
        text-align: left;        

        &:hover {
            background:var(--primary-100);
        }
    }

    

</style>