import {debounce} from '@/helpers.js';

const autoCompleteMixin = {
    props: {
        label: {
            type: String,
            required: true,
        },
        field: {
            type: String,
            required: true,
        },
        max_length: {
            type: Number,
            default: 20,
        },
        value: [String, Number],
        required: {
            type: Boolean, 
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    data(){
        return {
            input: this.value,
            deBouncedInput: '',
            results: [],
            loading: false,
        }
    },
    watch: {
        //component to write parent
        input: debounce(function (newVal) {
            this.$emit('update',newVal);        
            if (newVal && newVal != this.value && newVal.length > 0) {
                this.results = this.getAutoCompleteResults();
            }
            else if(typeof newVal === typeof undefined || newVal.length <=0) {
                this.results = [];
            }
        }, 300 ),
        //parent write component
        value: function(newVal) {
            this.input = newVal;
        }
    },

    methods: {
        selectResult(result) {
            this.$emit('update',result);
            this.results = [];
        },

        getAutoCompleteResults() {
            return;
        },
        emptyResults() {
            this.results = [];
        }
    }
}

export default autoCompleteMixin;