<template>
    <div class="autocompleteinput form-group" v-bind:class="{ required: required }">
        <input type="text" v-bind:id="field" v-bind:name="field" :required="required" v-bind:maxlength="max_length" v-model="input" :disabled="disabled">
        <label v-bind:for="field">{{label}}</label>
        <div v-if="results && results.length > 0" class="dropdown">
            <div class="results" v-for="result in results" v-bind:key="result">
                <p @click="selectResult(result)">{{result}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import {debounce} from '@/helpers.js';

export default {
    name: "AutoCompleteInput",
    props: {
        label: {
            type: String,
            required: true,
        },
        field: {
            type: String,
            required: true,
        },
        max_length: {
            type: Number,
            default: 20,
        },
        value: [String, Number],
        required: {
            type: Boolean, 
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    data(){
        return {
            input: this.value,
            deBouncedInput: '',
            results: [],
        }
    },
    watch: {
        //component write parent
        input: debounce(function (newVal) {
            this.$emit('update',newVal);            
            this.deBouncedInput = newVal;
            
            if (newVal && newVal != this.value && newVal.length > 0) {
                this.results = this.getAutoCompleteResults();
            }
            else if(newVal.length <=0) {
                this.results = [];
            }
        }, 300 ),

        //parent write component
        value: function(newVal) {
            this.input = newVal;
        }
    },
    methods: {
        selectResult(result) {
            this.$emit('update',result);
            this.results = [];
        },

        getAutoCompleteResults() {
            //Do override
            return;
        }
    }
}
</script>

<style lang="scss" scoped>

.dropdown {
    z-index: 10;
    background: white;
    width: 100%;
    border: 0.1px solid gray;
    margin-bottom: 1.2em;
}

.results p {
        cursor:pointer;
        padding:0.25rem 0.5rem;
        display: inline-block;
        width: 100%;
        text-align: left;        

        &:hover {
            background:var(--primary-100);
        }
    }
    

</style>